<template>
  <div class="col-12 col-lg-6 c g">
    <div class="row">
        <div class="col-12">
            <div class="card card-body item text-center" @click="$router.push('/_mentors/desinger')">
                <h3>
                    <img :src="require('@/assets/images/desinger.png')" alt="">
                    مصمم الكشوف
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_mentors/create')">
                <h3>
                    <img :src="require('@/assets/images/report.png')" alt="">
                    قائمة المشكلات
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_mentors/create2/1')">
                <h3>
                    <img :src="require('@/assets/images/tasks.png')" alt="">
                    الاستبانات
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_mentors/create2/2')">
                <h3>
                    <img :src="require('@/assets/images/forward.png')" alt="">
                    النماذج
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_mentors/kushof')">
                <h3>
                    <img :src="require('@/assets/images/myreport.png')" alt="">
                    كشوف المتابعة
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_mentors/files')">
                <h3>
                    <img :src="require('@/assets/images/file.png')" alt="">
                    السجلات المدرسية
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/archive?type=mentors')">
                <h3>
                    <img :src="require('@/assets/images/asks.png')" alt="">
                    ارشيف الرسائل
                </h3>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
        }
    }
}
</script>

<style>
.item{
    border: solid 2px #fff;
}
.item:hover{
    border: solid 2px #ddd;
    cursor: pointer;
    background: #fafafa;
}
.item img{
    width: 64px;
}
</style>